import React from 'react';

import PrivacyPolicy from '../../views/pages/legal/PrivacyPolicy';
import TermsOfUse from '../../views/pages/legal/TermsOfUse';

const Home = React.lazy(() => import('../../views/pages/home/Home'));

export const Pages: TPages = {
  Home: {
    Title: 'Home',
    path: '',
    element: <Home />,
  },
  PrivacyPolicy: {
    Title: 'Privacy Policy',
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  TermsOfUse: {
    Title: 'Terms Of Use',
    path: '/terms-of-use',
    element: <TermsOfUse />,
  },
};

export enum TransactionStatus {
  'READY',
  'PENDING',
  'STARTED',
  'SUCCEEDED',
  'FAILED',
  'REJECTED',
}
