export const colors = {
  MAIN_GREEN: '#12ba41',
  ERROR_500: '#f04438',
  GRAY_50: '#fafafa',
  GRAY_100: '#f2f2f3',
  GRAY_300: '#cacbce',
  GRAY_400: '#9FA2A7',
  GRAY_500: '#75797F',
  GRAY_600: '#5D6065',
  GRAY_700: '#45464a',
  GRAY_800: '#313235',
  GRAY_900: '#18191b',
  BASE_WHITE: '#FFFFFF',
  INDIGO_500: '#6172f3',
  INDIGO_500_HOVERED: '#444ce7',
  THEME_GREEN: '#BCFD5A',
  VIVID: '#FFC700',
  SKY_BLUE: '#6390FE',
  PINK: '#F84FA0',
};
