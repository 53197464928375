import './Layout/layout/Layout.css';

import React from 'react';

import {colors} from './constants/colors';

interface Props {
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  color?: string;
  height?: number;
  onClick?: (e?: any) => void;
  text?: string;
  textFont?: string;
  textColor?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  marginTop?: number;
  marginBottom?: number;
  classes?: string;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  width?: string | number;
  disabled?: boolean;
  hidden?: boolean;
}

const CommonButton: React.FC<Props> = ({
  borderColor = colors.GRAY_900,
  borderRadius = 20,
  borderWidth = 1,
  color = colors.GRAY_900,
  height = 48,
  onClick = () => {
    return;
  },
  rightIcon,
  leftIcon,
  text = 'Text',
  marginTop = 0,
  marginBottom = 0,
  paddingLeft = 24,
  paddingRight = 24,
  classes = 'base-white',
  width = 'fit-content',
  disabled = false,
  hidden = false,
}) => {
  const handleClick = () => {
    if (!disabled) onClick();
  };

  return (
    <div
      className={`custom-button txt-mono-regular txt-lh-130 txt-sz-xs ${classes}`}
      onClick={handleClick}
      style={{
        backgroundColor: color,
        border: `${borderWidth}px solid ${borderColor}`,
        borderRadius: borderRadius,
        height: height,
        width: width,
        marginTop: marginTop,
        marginBottom: marginBottom,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        textAlign: 'center',
        cursor: disabled ? 'default' : 'pointer',
        display: 'flex',
        visibility: hidden ? 'hidden' : 'visible',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: disabled ? 0.6 : 1,
      }}
    >
      {leftIcon && (
        <div style={{marginRight: 5, marginBottom: 1}} className='d-flex align-items-center'>
          {leftIcon}
        </div>
      )}
      {rightIcon ? (
        <div className='d-flex justify-content-between w-100'>
          <div>{text}</div>
          <div className='d-flex align-items-center'>{rightIcon ?? null}</div>
        </div>
      ) : (
        <div>{text}</div>
      )}
    </div>
  );
};

export default CommonButton;
