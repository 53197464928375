import Navbar from 'react-bootstrap/Navbar';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';

import CommonButton from '../../../Components/CommonButton';
import LogoFaucet from '../../../Components/icons/LogoFaucet';

function LegalHeader() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({top: element.offsetTop - 124, behavior: 'smooth'});
    }
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand='lg'
        className='oone-navbar'
        style={{position: 'sticky', top: '20px'}}
      >
        <div className='d-flex justify-content-space-between align-items-center w-100'>
          <div className='d-flex align-items-center' style={{gap: '12px'}}>
            <LogoFaucet size={42} />
            <Link to='/' className='d-flex'>
              <div className='txt-serif-regular txt-sz-m txt-lh-140'>Faucet</div>
            </Link>
          </div>
          <CommonButton
            onClick={() => {
              navigate('/');
              handleClickScroll('home-connect-wallet-section');
            }}
            text={'CONNECT YOUR WALLET'}
            classes='base-white'
            height={40}
            marginTop={0}
            marginBottom={0}
          />
        </div>
      </Navbar>
    </>
  );
}

export default LegalHeader;
