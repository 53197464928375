import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: number;
}

const LogoFaucet: FC<iconProps> = ({color = colors.GRAY_900, size = 50, ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.4103 10.7387C11.2298 10.9428 11.0405 11.1443 10.8424 11.3423C7.97858 14.2062 4.39065 15.2614 2.82855 13.6993C1.75762 12.6284 1.91688 10.6053 3.04573 8.51612M4.59319 6.3168C4.781 6.10326 4.97856 5.89246 5.18557 5.68545C8.04942 2.82161 11.6374 1.76633 13.1995 3.32843C14.2711 4.4001 14.1109 6.42526 12.9799 8.51596M10.8424 5.68545C13.7063 8.5493 14.7615 12.1372 13.1995 13.6993C11.6374 15.2614 8.04942 14.2062 5.18557 11.3423C2.32173 8.47846 1.26645 4.89053 2.82855 3.32843C4.39065 1.76633 7.97858 2.82161 10.8424 5.68545ZM8.66679 8.49995C8.66679 8.86814 8.36831 9.16662 8.00012 9.16662C7.63193 9.16662 7.33346 8.86814 7.33346 8.49995C7.33346 8.13176 7.63193 7.83329 8.00012 7.83329C8.36831 7.83329 8.66679 8.13176 8.66679 8.49995Z'
      stroke={color}
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default LogoFaucet;
