import './translation/i18n';

import {BigNumber} from 'ethers';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

Object.defineProperty(String.prototype, 'hexToDecimalString', {
  value() {
    return BigNumber.from(this).toString();
  },
});

Object.defineProperty(String.prototype, 'hexToNumber', {
  value() {
    return Number(BigNumber.from(this).toString());
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
