import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './english.json';

i18next.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  resources: {en},
});
