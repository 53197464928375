import './assets/css/App.css';
import './assets/css/App-mobile.css';

import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {RecoilRoot} from 'recoil';

import {Pages} from './Helpers/constants';

const FullscreenLoading = React.lazy(() => import('./Components/FullscreenLoading'));
const Layout = React.lazy(() => import('./Components/Layout/layout/Layout'));
const Page404 = React.lazy(() => import('./views/pages/Page404'));

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={<FullscreenLoading />}>
          <Routes>
            <Route path='' element={<Layout />}>
              <Route index path={Pages.Home.path} element={Pages.Home.element} />
              <Route path={Pages.PrivacyPolicy.path} element={Pages.PrivacyPolicy.element} />
              <Route path={Pages.TermsOfUse.path} element={Pages.TermsOfUse.element} />
              <Route path='*' element={<Page404 />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
